.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
  overflow: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  display: -webkit-box;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 200vw;
    height: 250vw;
    color: #fefefe;
    background: rgba(6, 19, 43, 0.97);
    border-radius: 50%;
    transition: all 0.4s ease;
    flex: none;
    transform: scale(0);
    backface-visibility: hidden;
    overflow: hidden;
    display: -webkit-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ul {
      list-style: none;
      padding: 0 1em;
      margin: 0;
      display: block;
      max-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > li {
        padding: 0;
        margin: 1em;
        font-size: 24px;
        display: block;
        > a {
          position: relative;
          text-decoration: none;
          color: color(white);
          display: inline;
          cursor: pointer;
          transition: color 0.4s ease;
          &:hover {
            color: color(orange-color);
          }
        }
      }
    }
  }
}
