.nav {
  ul {
    list-style: none;
    display: flex;
    li {
      a {
        padding: 10px;
        text-decoration: none;
        text-transform: uppercase;
        color: color(white);
        transition: all 0.3s ease-in-out;
        display: block;
        text-align: center;
        &:hover {
          color: color(orange-color);
        }
      }
    }
  }
}
