.contact {
  padding: 100px 15px;
  display: grid;
  grid-template-columns: 1fr;
  @include for-tablet-landscape-up {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "address form" "map map";
  }
  gap: 80px;
  grid-template-areas: "address" "form" "map";
  .h4 {
    margin-bottom: 20px;
    color: color(orange-color);
  }
  &__address {
    grid-area: address;
    display: flex;
    flex-direction: column;
    color: color(white);
    a {
      margin: 10px 0;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      &:hover {
        font-weight: 700;
      }
      &:first-of-type {
        margin-top: 20px;
      }
    }
  }
  &__form {
    grid-area: form;
    form {
      display: flex;
      flex-direction: column;
      color: color(orange-color);
      label {
        margin-bottom: 10px;
        color: color(white);
        input,
        textarea {
          width: 100%;
          margin-top: 5px;
          padding: 10px;
        }
      }
    }
  }
  &__map {
    grid-area: map;
    iframe {
      border: none;
      width: 100%;
      height: 450px;
    }
  }
}
