.home {
  &__about-us {
    padding: 80px 15px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "title" "text" "img";
    @include for-tablet-landscape-up {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "title title" "img text";
    }
    justify-items: center;
    gap: 100px;
    h2 {
      grid-area: title;
      text-align: center;
      color: color(orange-color);
    }
    &__image {
      grid-area: img;
      max-width: 400px;
      img {
        width: 100%;
      }
    }
    &__text {
      grid-area: text;
      align-self: center;
      max-width: 400px;
      p {
        margin-bottom: 50px;
        color: color(white);
      }
    }
  }
  &__offer {
    padding: 80px 15px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "title" "text" "img";
    @include for-tablet-landscape-up {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "title title" "text img";
    }
    justify-items: center;
    gap: 100px;
    h2 {
      grid-area: title;
      text-align: center;
      color: color(orange-color);
      max-width: 400px;
    }
    &__text {
      grid-area: text;
      align-self: center;
      max-width: 400px;
      p {
        margin-bottom: 50px;
        color: color(white);
      }
    }
    &__image {
      grid-area: img;
      max-width: 400px;
      @include for-phone-only {
        max-width: 300px;
      }
      img {
        max-width: 283px;
      }
    }
  }
}
