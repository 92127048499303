.about {
  padding: 100px 15px;
  &__content {
    &__top {
      margin-bottom: 80px;
      color: color(white);
    }
    &__bottom {
      display: grid;
      grid-template-columns: 1fr;
      @include for-tablet-landscape-up {
        grid-template-columns: 1fr 1fr;
        gap: 80px;
      }
      gap: 40px;
      &__left {
        p {
          margin-bottom: 40px;
          color: color(white);
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      &__right {
        p {
          margin-bottom: 40px;
          color: color(white);
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
