.flex-center {
  display: flex;
  align-items: center;
}

.outer-menu {
  display: block;
  top: 10vh;
  left: 0;
  z-index: 1;
  .checkbox-toggle {
    position: fixed;
    top: -10px;
    right: 10px;
    z-index: 2;
    cursor: pointer;
    width: 80px;
    height: 80px;
    opacity: 0;
    transform: skewY(5deg);
    &:checked {
      + .hamburger > div {
        transform: rotate(135deg);
        &::before,
        &::after {
          top: 0;
          transform: rotate(90deg);
        }
        &::after {
          opacity: 0;
        }
      }
      ~ .mobile-nav {
        pointer-events: auto;
        visibility: visible;
        > div {
          transform: scale(1);
          transition-duration: 0.75s;
          > ul {
            opacity: 1;
            transition: opacity 0.4s ease 0.4s;
          }
        }
      }
      &:hover {
        + .hamburger > div {
          transform: rotate(225deg);
        }
      }
    }
    &:hover {
      + .hamburger {
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .hamburger {
    position: fixed;
    top: -10px;
    right: 10px;
    z-index: 1;
    width: 80px;
    height: 80px;
    padding: 0.5em 1em;
    background: color(white);
    border-radius: 10px;
    cursor: pointer;
    transition: box-shadow 0.4s ease;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: skewY(5deg);
    > div {
      position: relative;
      flex: none;
      width: 100%;
      height: 2px;
      background: color(main-color);
      transition: all 0.4s ease;
      display: -webkit-box;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before,
      &::after {
        content: "";
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background: inherit;
        transition: all 0.4s ease;
      }
      &::after {
        top: 10px;
      }
    }
  }
}

@include for-desktop-up {
  .outer-menu {
    display: none;
  }
}
