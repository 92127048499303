.breadcrumbs {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 120px 0;
  &__container {
    h1 {
      color: color(white);
      margin-top: 100px;
      margin-bottom: 20px;
    }
    h2 {
      color: color(orange-color);
    }
  }
}
