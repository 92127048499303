.footer {
  // background-color: color(main-color);
  &__container {
    &__content {
      display: grid;
      grid-template-columns: 1fr;
      @include for-tablet-landscape-up {
        grid-template-columns: repeat(3, 1fr);
      }
      gap: 80px;
      color: color(text-color);
      padding: 80px 0;
      .h4 {
        color: color(white);
        margin-bottom: 30px;
      }
      &__about,
      &__address,
      &__menu {
        max-width: 350px;
      }
      &__about {
        p {
          margin-bottom: 20px;
        }
        a {
          color: color(orange-color);
          transition: all 0.3s ease-in-out;
          font-size: 1.8rem;
          line-height: 2.6rem;
          &:hover {
            color: color(white);
          }
        }
      }
      &__address {
        display: flex;
        flex-direction: column;
        p {
          margin-bottom: 20px;
        }
        a {
          margin-bottom: 10px;
          font-size: 1.8rem;
          line-height: 2.6rem;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: color(white);
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      &__menu {
        ul {
          list-style: none;
          padding: 0;
          li {
            a {
              display: block;
              text-decoration: none;
              font-size: 1.8rem;
              line-height: 2.6rem;
              padding: 10px 0;
              transition: all 0.3s ease-in-out;
              &:hover {
                color: color(white);
              }
            }
          }
        }
      }
      &__company {
        display: flex;
        justify-content: center;
      }
    }
    &__copyright {
      border-top: 1px solid rgba(244, 244, 244, 0.5);
      padding: 30px 0;
      color: color(text-color);
      display: flex;
      flex-direction: column;
      @include for-tablet-landscape-up {
        flex-direction: row;
        p {
          margin-bottom: 0;
        }
      }
      justify-content: space-between;
      p {
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
        @include for-tablet-landscape-up {
          margin-bottom: 0;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
}
