$containers: (1440, 1600, 960, 680);

@each $container in $containers {
  .container-#{$container} {
    @include container($container);
  }
}

#root {
  // background-image: url("../../assets/img/background.png");
  background: linear-gradient(
      180deg,
      rgba(6, 19, 43, 0.85) 0%,
      rgba(6, 19, 43, 1) 100%
    ),
    url("../../assets/img/background.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}
