@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-feature-settings: "pnum" on, "lnum" on;
}

h1,
.h1 {
  font-size: 9.6rem;
  line-height: 9.8rem;
}

h2,
.h2 {
  font-size: 6.4rem;
  line-height: 6.6rem;
}
h3,
.h3 {
  font-size: 4.8rem;
  line-height: 5.6rem;
}
h4,
.h4 {
  font-size: 3.6rem;
  line-height: 4.3rem;
}

h5,
.h5 {
  font-size: 2.4rem;
  line-height: 3.6rem;
}

h6,
.h6 {
  font-size: 1.8rem;
  line-height: 2.7rem;
}

@include for-phone-only {
  h1,
  .h1 {
    font-size: (9.6 * 0.44) * 1rem;
    line-height: (9.8 * 0.44) * 1rem;
  }
  h2,
  .h2 {
    font-size: (6.4 * 0.55) * 1rem;
    line-height: (6.6 * 0.55) * 1rem;
  }
  h3,
  .h3 {
    font-size: (4.8 * 0.66) * 1rem;
    line-height: (5.6 * 0.66) * 1rem;
  }
  h4,
  .h4 {
    font-size: (3.6 * 0.77) * 1rem;
    line-height: (4.3 * 0.77) * 1rem;
  }
  h5,
  .h5 {
    font-size: (2.4 * 0.88) * 1rem;
    line-height: (3.6 * 0.88) * 1rem;
  }
  h6,
  .h6 {
    font-size: (1.8 * 0.99) * 1rem;
    line-height: (2.7 * 0.99) * 1rem;
  }
}

p,
.p {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.6rem;
}
.p-bold {
  font-weight: bold;
}

a {
  text-decoration: none;
  text-decoration-line: underline;
  &,
  &:link,
  &:visited {
    color: inherit;
  }

  &:hover,
  &:active {
    color: inherit;
  }
}
