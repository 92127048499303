.cooperation {
  padding: 100px 15px;
  p {
    margin-bottom: 50px;
    color: color(white);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
