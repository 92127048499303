@mixin for-phone-only {
  @media (max-width: map-get($map: $breakpoints, $key: for-phone-only)) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: map-get($map: $breakpoints, $key: for-tablet-portrait-up)) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: map-get($map: $breakpoints, $key: for-tablet-landscape-up)) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: map-get($map: $breakpoints, $key: for-desktop-up)) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: map-get($map: $breakpoints, $key: for-big-desktop-up)) {
    @content;
  }
}

@mixin container($width: 1440) {
  max-width: #{$width + 30}px;
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
}
