.btn {
  padding: 15px 30px;
  border: 1px solid color(orange-color);
  font-size: 1.8rem;
  line-height: 2.6rem;
  border-radius: 10px;
  background-color: color(orange-color);
  transition: all 0.3s ease-in-out;
  color: color(main-color) !important;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    border: 1px solid color(white);
    background-color: color(white);
    transition: all 0.3s ease-in-out;
  }
}
