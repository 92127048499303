.header {
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    transition: all 0.3s ease-in-out;
    &__logo {
      margin-top: -20px;
      img {
        width: 250px;
      }
    }
  }
  &__right-column {
    display: none;
    @include for-desktop-up {
      display: flex;
    }
    align-items: center;
    &__contact {
      margin-left: 20px;
      display: flex;
      width: 320px;
      a {
        margin-right: 10px;
        color: color(white);
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
        }
        &:last-of-type {
          margin-right: 0;
        }
        &:hover {
          color: color(orange-color);
        }
      }
    }
  }
}
